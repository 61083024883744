<template>
  <base-section
    id="map"
    space="0"
  >
    <iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2625.8965942378927!2d2.3650586124141304!3d48.84111107121021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDjCsDUwJzI4LjAiTiAywrAyMicwMy41IkU!5e0!3m2!1sfr!2sfr!4v1722417423446!5m2!1sfr!2sfr"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
